import React from "react"
import Img from "gatsby-image"

const FeaturesBanner = (props) => {
  const { data } = props;

  return (
    <section id={data.features_banner_id.text ? data.features_banner_id.text : ""} className="features-banner fb">
      <div className="inner">
        <div className="fb__content-wrapper">
          <div className={"fb__title-wrapper"}>
            {data.features_banner_title.text && (
              <h3
                className="section-title fb__title"
                data-sal="slide-right"
                data-sal-delay="0"
              >
                {data.features_banner_title.text}
              </h3>
            )}
            {data.features_banner_description.html && (
              <div
                className="section-text"
                dangerouslySetInnerHTML={{__html: data.features_banner_description.html}}
                data-sal="slide-right"
                data-sal-delay="300"
              >
              </div>
            )}
          </div>
          {data.features_banner_items && data.features_banner_items.length > 0 && (
            <div className="fb__features-wrapper">
              <ul className="fb__features">
                {data.features_banner_items.map((item, index) => (
                  <li
                    key={index}
                    className="fb__feature"
                    data-sal="slide-up"
                    data-sal-delay={200 * (index + 1)}
                  >
                    <Img
                      className="fb__feature-image"
                      fluid={item.image.fluid}
                      alt={item.image.alt}
                    />
                    {item.image_description.text && (
                      <h6 className="fb__feature-text">{item.image_description.text}</h6>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default FeaturesBanner
