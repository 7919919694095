import React from "react"
import CustomLink from "./customLink"
import { triggerRequestDemoClick } from "./_helpers/triggerRequestDemoClick"

const Banner = (props) => {
  const { data } = props;

  return (
    <section id={data.banner_id.text ? data.banner_id.text : ""} className="banner">
      <div className="inner">
        {data.banner_title.text && (
          <div className={"banner__title-wrapper"}>
            <h3
              className="section-title banner__title"
              data-sal="slide-up"
              data-sal-delay="150"
            >
              {data.banner_title.text}
            </h3>
          </div>
        )}
        <div className="banner__link-wrapper">
          {data.banner_link && data.banner_link.url ? (
            <CustomLink
              link={data.banner_link}
              className="button violet"
              data-sal="slide-down"
              data-sal-delay="150"
            >
              {data.banner_link_text.text}
            </CustomLink>
          ) : (
            <button onClick={triggerRequestDemoClick} className="button violet">
              {data.banner_link_text.text}
            </button>
          )}
        </div>
      </div>
    </section>
  )
}

export default Banner
