import React from "react"
import Img from "gatsby-image"
import CustomLink from "./customLink"

const TwoColImageText = (props) => {
  const { data } = props;

  return (
    <section id={data.tcit_id.text ? data.tcit_id.text : ""} className="tcit">
      <div className="inner">
        <div className="tcit__title-wrapper">
          {data.tcit_title.text && (
            <h3
              className="tcit__title section-title"
              data-sal="slide-up"
              data-sal-delay="100"
            >
              {data.tcit_title.text}
            </h3>
          )}
          {data.tcit_description.html && (
            <div
              className="tcit__description section-text"
              dangerouslySetInnerHTML={{__html: data.tcit_description.html}}
              data-sal="slide-up"
              data-sal-delay="400"
            >
            </div>
          )}
        </div>
        {data.tcit_items && data.tcit_items.length > 0 && (
          <div className="tcit__list-wrapper">
            <ul className="tcit__list">
              {data.tcit_items.map((item, index) => (
                <li key={index}
                    className="tcit__item"
                    data-sal="slide-right"
                    data-sal-delay="100"
                >
                  <Img
                    className="tcit__item-image"
                    fixed={item.image.fixed}
                    alt={item.image.alt}
                  />
                  <div
                    className="tcit__item-title-wrapper"
                    data-sal="slide-left"
                    data-sal-delay="100"
                  >
                    {item.title.text && (
                      <h6 className="tcit__item-title">{item.title.text}</h6>
                    )}
                    {item.description.html && (
                      <div
                        className="tcit__item-description section-text__sm"
                        dangerouslySetInnerHTML={{__html: item.description.html}}>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {data.tcit_link && data.tcit_link.url && (
          <div
            className="tcit__link-wrapper"
            data-sal="slide-up"
            data-sal-delay="300"
          >
            <CustomLink
              link={data.tcit_link}
              className="button violet"
            >
              {data.tcit_link_text.text}
            </CustomLink>
          </div>
        )}
      </div>
    </section>
  )
}

export default TwoColImageText
