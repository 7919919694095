import React from "react"
import Img from "gatsby-image"

const ProductFeatures = (props) => {
  const { data } = props;

  return (
    <section id={data.product_features_id.text ? data.product_features_id.text : ""} className="product-features pf">
      <div className="inner">
        <div className="pf__wrapper">
          <div className="pf__title-wrapper">
            {data.product_features_title.text && (
              <h3
                className={`section-title pf__title`}
                data-sal="slide-down"
                data-sal-delay="0"
              >
                {data.product_features_title.text}
              </h3>
            )}
            {data.product_features_description.html && (
              <div
                className="section-text"
                dangerouslySetInnerHTML={{__html: data.product_features_description.html}}
                data-sal="slide-up"
                data-sal-delay="300"
              >
              </div>
            )}
          </div>
          <div className="pf__features-wrapper">
            <div
              className="pf__feature-image-wrapper"
              data-sal="slide-right"
              data-sal-delay="900"
            >
              <Img
                className="pf__feature-image"
                fluid={data.product_features_image.fluid}
                alt={data.product_features_image.alt}
              />
              {data.product_features_image_description.text && (
                <span className="pf__feature-image-description">{data.product_features_image_description.text}</span>
              )}
            </div>
            {data.product_features_items && data.product_features_items.length > 0 && (
              <ul className="pf__features">
                {data.product_features_items.map((feature, index) => (
                  <li
                    key={index}
                    className="pf__feature section-text"
                    data-sal="slide-up"
                    data-sal-delay={100 * (index + 1)}
                  >
                    {feature.feature.text}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProductFeatures
