import { useStaticQuery, graphql } from "gatsby"

const FeaturesBannerData = () => {
  const data = useStaticQuery(graphql`
      {
          prismicTrustCenterPage {
              data {
                  features_banner_id {
                      text
                  }
                  features_banner_title {
                      text
                  }
                  features_banner_description {
                      html
                  }
                  features_banner_items {
                      image {
                          fluid(maxWidth: 200) {
                              ...GatsbyPrismicImageFluid
                          }
                          alt
                      }
                      image_description {
                          text
                      }
                  }
              }
          }
      }
  `)
  return data.prismicTrustCenterPage.data
}

export default FeaturesBannerData

