import { useStaticQuery, graphql } from "gatsby"

const ImageFeaturesData = () => {
  const data = useStaticQuery(graphql`
      {
          prismicTrustCenterPage {
              data {
                  image_features_id {
                      text
                  }
                  image_features_title {
                      text
                  }
                  image_features_description {
                      html
                  }
                  image_features {
                      feature {
                          text
                      }
                  }
                  image_features_image {
                      fluid(maxWidth: 600) {
                          ...GatsbyPrismicImageFluid
                      }
                      alt
                  }
              }
          }
      }
  `)
  return data.prismicTrustCenterPage.data
}

export default ImageFeaturesData

