import { useStaticQuery, graphql } from "gatsby"

const TwoColImageTextData = () => {
  const data = useStaticQuery(graphql`
      {
          prismicTrustCenterPage {
              data {
                  tcit_id {
                      text
                  }
                  tcit_title {
                      text
                  }
                  tcit_description {
                      html
                  }
                  tcit_items {
                      title {
                          text
                      }
                      description {
                          html
                      }
                      image {
                          fixed(height: 300, width: 300) {
                              ...GatsbyPrismicImageFixed
                          }
                          alt
                      }
                  }
                  tcit_link {
                      target
                      url
                      uid
                      type
                  }
                  tcit_link_text {
                      text
                  }
              }
          }
      }
  `)
  return data.prismicTrustCenterPage.data
}

export default TwoColImageTextData

