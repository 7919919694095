import { useStaticQuery, graphql } from "gatsby"

const TrustCenterPage = () => {
  const data = useStaticQuery(graphql`
      {
          prismicTrustCenterPage {
              data {
                  page_title {
                      text
                  }
              }
          }
      }
  `)
  return data.prismicTrustCenterPage.data
}

export default TrustCenterPage

