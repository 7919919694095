import React from "react";
import scrollTo from 'gatsby-plugin-smoothscroll';

const IntroSection = (props) => {
  const { data } = props;

  const goToSection = () => {
    scrollTo('#banner');
  }

  return (
    <section id={data.intro_section_id.text ? data.intro_section_id.text : ""} className="hero">
      <div className="inner">
        <div className="intro-section">
          {data.intro_section_title.text && (
            <h3 className="intro-section__title" data-sal="slide-up" data-sal-delay="0">{data.intro_section_title.text}</h3>
          )}
          {data.intro_section_description.html && (
            <div
              className="intro-section__description"
              dangerouslySetInnerHTML={{__html: data.intro_section_description.html}}
              data-sal="slide-up" data-sal-delay="300"
            >
            </div>
          )}
          {data.intro_section_links_group && data.intro_section_links_group.length > 0 && (
            <div className="intro-section__anchor-wrapper">
              {data.intro_section_links_group.map((item, index) => (
                <button
                  key={index}
                  className="intro-section__anchor outline-dark"
                  data-sal="slide-left" data-sal-delay={300 * (index + 1)}
                  value={item.section_id.text}
                  onClick={(e) => scrollTo("#" + e.currentTarget.value)}
                >
                  {item.button_text.text}
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default IntroSection
