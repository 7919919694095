import { useStaticQuery, graphql } from "gatsby"

const IntroSectionData = () => {
  const data = useStaticQuery(graphql`
      {
          prismicTrustCenterPage {
              data {
                  intro_section_id {
                      text
                  }
                  intro_section_title {
                      text
                  }
                  intro_section_description {
                      text
                      html
                  }
                  intro_section_links_group {
                      button_text {
                          text
                      }
                      section_id {
                          text
                      }
                  }
              }
          }
      }
  `)
  return data.prismicTrustCenterPage.data
}

export default IntroSectionData

