import { useStaticQuery, graphql } from "gatsby"

const BannerData = () => {
  const data = useStaticQuery(graphql`
      {
          prismicTrustCenterPage {
              data {
                  banner_id {
                      text
                  }
                  banner_title {
                      text
                  }
                  banner_link {
                      uid
                      type
                      url
                      target
                  }
                  banner_link_text {
                      text
                  }
              }
          }
      }
  `)
  return data.prismicTrustCenterPage.data
}

export default BannerData

