import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Import Modules
import IntroSection from "../components/introSection"
import TwoColImageText from "../components/twoColImageText"
import Banner from "../components/banner"
import FeaturesBanner from "../components/featuresBanner"
import ProductFeatures from "../components/productFeatures"
import ImageFeatures from "../components/imageFeatures"

// Import Data
import trustedCenterPageData from "../hooks/pages/trust-center"
import IntroSectionData from "../hooks/components/introSection"
import TwoColImageTextData from "../hooks/components/twoColImageText"
import ImageFeaturesData from "../hooks/components/ImageFeatures"
import ProductFeaturesData from "../hooks/components/productFeatures"
import FeaturesBannerData from "../hooks/components/featuresBanner"
import BannerData from "../hooks/components/banner"

const TrustCenterPage = () => {
  const pageData = trustedCenterPageData();
  const introSectionData = IntroSectionData();
  const twoColImageTextData = TwoColImageTextData();
  const imageFeaturesData = ImageFeaturesData();
  const productFeaturesData = ProductFeaturesData();
  const featuresBannerData = FeaturesBannerData();
  const bannerData = BannerData();

  return (
    <Layout mainClass="product-page">
      <SEO title={pageData.page_title.text} />
      <IntroSection data={introSectionData} />
      <TwoColImageText data={twoColImageTextData} />
      <ImageFeatures data={imageFeaturesData} />
      <ProductFeatures data={productFeaturesData} />
      <FeaturesBanner data={featuresBannerData} />
      <Banner data={bannerData} />
    </Layout>
  )
}

export default TrustCenterPage
