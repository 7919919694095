import React from "react"
import Img from "gatsby-image"

const ImageFeatures = (props) => {
  const { data } = props;

  return (
    <section id={data.image_features_id.text ? data.image_features_id.text : ""} className="image-features">
      <div className="image-features__full-wrapper">
        <div className="image-features__content-wrapper">
          <div
            className="image-features__image-wrapper"
            data-sal="slide-right"
            data-sal-delay="100"
          >
            <Img
              className="image-features__image"
              fluid={data.image_features_image.fluid}
              alt={data.image_features_image.alt}
            />
          </div>
          <div className="image-features__features-wrapper">
            {data.image_features_title.text && (
              <div
                className="image-features__title-wrapper"
                data-sal="slide-up"
                data-sal-delay="200"
              >
                <h3 className="image-features__title section-title">{data.image_features_title.text}</h3>
              </div>
            )}
            {data.image_features_description.html && (
              <div
                className="image-features__description-wrapper"
                data-sal="slide-up"
                data-sal-delay="400"
              >
                <div
                  className="image-features__description section-text"
                  dangerouslySetInnerHTML={{__html: data.image_features_description.html}}>
                </div>
              </div>
            )}
            {data.image_features && data.image_features.length > 0 && (
              <div className="image-features__features-items-wrapper">
                <ul className="image-features__features section-text__sm">
                  {data.image_features.map((feature, index) => (
                    <li
                      key={index}
                      className="image-features__feature"
                      data-sal="slide-up"
                      data-sal-delay={100 * (index + 1)}
                    >
                      {feature.feature.text}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default ImageFeatures
